import React from "react";
import styled from "styled-components";
import config from "../../../assets/config";

const Container = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: ${config.theme.whiteColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SecondLayer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${config.theme.cameraBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FirstLayer = styled.div`
  height: 38px;
  width: 38px;
  border-radius: 19px;
  background-color: ${config.theme.whiteColor};
`;

interface CaptureImageButtonProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function CaptureImageButton({ onClick }: CaptureImageButtonProps) {
  return (
    <Container onClick={onClick}>
      <SecondLayer>
        <FirstLayer />
      </SecondLayer>
    </Container>
  );
}

export default CaptureImageButton;
