export default {
  screenHeader: {},
  theme: {
    categoryDropdown: "rgb(53,64,82)",
    subcategoryDropdown: "rgb(79,133,255)",
    claimtypeDropdown: "rgb(82,53,81)",
    whiteColor: "#fff",
    darkColor: "rgb(53,63,82)",
    cameraBackgroundColor: "rgb(41, 50, 60)",
    disabledColor: "rgb(199,199,199)",
    lightGrey: "rgb(198, 205, 218)",
    lightGrey50: "rbg(216,216,216)",
    blueBackground: "rgb(250, 251, 254)",
    blue: "rgb(79, 109, 255)",
    blue25: "rgb(74, 113, 255)",
    blue50: "rgb(49,93,255)",
    blueHeaderIcon: "rgb(79, 109, 255)",
    brown: "rgb(82,53,81)",
    iconColor: "rgb(151, 165, 188)",
    iconColor10: "rgba(151, 165, 188, 0.1)",
    shadowColor: "rgb(220, 226, 254)",
    textColor65: "rgba(53, 64, 82, 0.65)",
    textColor: "rgb(53, 64, 82)",
    greyBackground: "rgb(242,242,242)",
    greyBorder: "#E6E6E6",
    greyFont: "#AAAAAA",
    green: "rgb(22,170,9)",
    green10: "#E2F4E0",
    darkFont: "rgb( 53, 64, 82)",
    fontFamily: "Montserrat",
    fontFamilyLight: "MontserratLight",
    fontFamilyMedium: " MontserratMedium",
    fontFamilyBold: "MontserratBold",
    fontFamilySemiBold: "MontserratSemiBold",
    fontSizeExtraBig: "36px",
    fontSizeMediumBig: "26px",
    fontSizeBig: "24px",
    fontSizeNormal: "18px",
    fontSizeMedium: "16px",
    fontSizeSmall: "14px",
    fontSizeTiny: "12px",
    fontSizeSuperTiny: "10px",
    positiveColor: "#44DB5E",
    warningColor: "rgb(235, 175, 84)",
    negativeColor: "red"
  }
};
