import axios from 'axios';

const customAxios = axios.create();

const ApiUtils = {
  async get(url: string) {
    const requestConfig = await createRequestConfig();
    return customAxios.get(url, requestConfig);
  },

  async post(url: string, body: any) {
    const requestConfig = await createRequestConfig();
    return customAxios.post(url, body, requestConfig);
  },

  async postFiles(url: string, body: any) {
    const requestConfig = await createRequestConfigForFiles();
    return customAxios.post(url, body, requestConfig)
  }

  ,
  async put(url: string, body: any) {
    const requestConfig = await createRequestConfig();
    return customAxios.put(url, body, requestConfig);
  },

  async patch(url: string, body: any) {
    const requestConfig = await createRequestConfig();
    return customAxios.patch(url, body, requestConfig);
  },

  async delete(url: string) {
    const requestConfig = await createRequestConfig();
    return customAxios.delete(url, requestConfig);
  }
};

async function createRequestConfig() {
  return {
    baseURL: 'https://expenseclaim-dev.apps.bankify.io',
    timeout: 500000,
    crossdomain: true,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("BearerToken")}`
    }
  };
}


async function createRequestConfigForFiles() {
  return {
    baseURL: 'https://expenseclaim-dev.apps.bankify.io',
    timeout: 500000,
    crossdomain: true,
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("BearerToken")}`
    }
  };
}

export default ApiUtils;
