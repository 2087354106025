import styled from 'styled-components';
import React from 'react';
import config from '../assets/config';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomRow = styled(Row)<{
  justifyContent: 'center' | 'space-between' | 'space-evenly' | 'space-around';
}>`
  justify-content: ${props => props.justifyContent};
`;

interface RowWithIconTextInterface {
  icon: React.ReactNode;
  text: string;
  color: string;
}
export const RowWithIconText = ({
  icon,
  text,
  color
}: RowWithIconTextInterface) => {
  const Text = styled.p`
    font-family: ${config.theme.fontFamily};
    font-size: ${config.theme.fontSizeSmall};
    color: ${color};
    margin-left: 6px;
  `;

  return (
    <Row>
      {icon}
      <Text>{text}</Text>
    </Row>
  );
};
