import React from "react";
import { NavTab as Tab } from "react-router-tabs";
import styled from "styled-components";
import config from "../assets/config";
import avatar from "../assets/images/avatar.png";
import disabledAvatar from "../assets/images/disabledAvatar.png";
import wallet from "../assets/images/wallet.png";
import disabledWallet from "../assets/images/disabledWallet.png";

const RouteTabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  padding: 10px;
  background-color: ${config.theme.whiteColor};
`;

const Image = styled.img`
  height: 26px;
  width: 26px;
  display: flex;
  margin: auto;
`;

const Text = styled.div<{ selected?: boolean }>`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSuperTiny};
  color: ${(props) => props.selected ? config.theme.blue25 : config.theme.greyFont};
  text-align: center;
  text-transform: uppercase;
`;

interface NavTabProps {
  pathName?: string
}
export default function NavTab({ pathName }: NavTabProps) {
  let [isSelected, setSelected] = React.useState(pathName);
  return (
    <RouteTabs>
      <Tab
        onClick={() => setSelected("/expenses")}
        style={{
          flex: 1,
          flexDirection: "column",
          textDecoration: "none"
        }}
        to="/expenses"
      >
        <Image src={isSelected === "/expenses" ? wallet : disabledWallet} />
        <Text selected={isSelected === "/expenses" ? true : false}>Expenses</Text>
      </Tab>
      <Tab
        onClick={() => setSelected("/profile")}
        style={{
          flex: 1,
          flexDirection: "column",
          textDecoration: "none"
        }}
        to="/profile"
      >
        <Image src={isSelected === "/profile" ? avatar : disabledAvatar} />
        <Text selected={isSelected === "/profile" ? true : false}>Profile</Text>
      </Tab>
    </RouteTabs>
  );
}
