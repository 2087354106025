import React, { useState } from "react";
import styled from "styled-components";
import config from "../../../assets/config";
import Search from "@material-ui/icons/Search";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0.5px 0.5px 1px 1px ${config.theme.shadowColor};
  background-color: ${config.theme.whiteColor};
  border-radius: 6px;
  min-height: 50px;
  padding: 10px 20px;
  margin-bottom: -25px;
  border: none;
  box-shadow: 0px 2px 9px 0px rgba(41, 50, 60, 0.15);
`;

const Input = styled.input`
  height: 40px;
  border: none;
  outline: none;
  font-family: ${config.theme.fontFamilyMedium};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.darkColor};
  flex: 1;
  ::placeholder {
    color: ${config.theme.iconColor};
  }
`;

interface SearchInputProps {
  onChangeSearch: (string: string) => void
}


function SearchInput({ onChangeSearch }: SearchInputProps) {


  const handleChange = (e: any) => {
    onChangeSearch(e.target.value)
  }

  return (
    <Container>
      <Input placeholder="Type something here..." onChange={handleChange} />
      <Search
        // onClick={onClickSearch(searchTerm)}

        style={{ height: 30, width: 30, color: config.theme.iconColor }}
      />
    </Container>
  );
}

export default SearchInput;
