import React, { useState, useEffect } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import { RouteComponentProps, Redirect } from "react-router-dom";
import styled from "styled-components";
import config from "../../assets/config";
import { Container } from "../../components/StyledComponents";
import DropdownInput from "../../components/DropdownInput";
import { getIconName } from "../../utils/iconName";
import {
  postExpenses,
  getVendors,
  getTaxRates
} from "../../services/ScannerServices";
import { useCategory, SET_SUBCATEGORY } from "../../context/CategoryContext";
import { CircularProgress } from "@material-ui/core";

const TitleText = styled.h6`
  margin: 0 0 10px 10px;
  font-family: ${config.theme.fontFamily};
  color: ${config.theme.iconColor};
  font-size: ${config.theme.fontSizeSmall};
`;

const TextArea = styled.textarea`
  outline: none;
  border: none;
  border-radius: 23px;
  box-shadow: 1px 1px 5px 1px ${config.theme.shadowColor};
  width: 100%;
  padding: 20px;
  font-size: ${config.theme.fontSizeSmall};
  font-family: ${config.theme.fontFamily};
`;

interface Vendor {
  id: number;
  name: string;
  ytunnus: string;
}

interface Tax {
  id: number;
  name: string;
  percentage: number;
}

interface DetailScreenInterface extends RouteComponentProps {}
export default function DetailScreen({ history }: DetailScreenInterface) {
  const { categories, dispatchCategory } = useCategory();

  const fullData = history.location.state
    ? history.location.state.fullData
    : null;

  let newExpenses: [];
  newExpenses =
    fullData.expenses !== null
      ? fullData.expenses.map((expense: any) => {
          return (expense = Object.assign(
            {},
            {
              Name: expense.item,
              GrossAmount: expense.price
            }
          ));
        })
      : [];

  const claimTypes = [{ name: "Reimbursements" }, { name: "Company Card" }];
  const [claimType, setClaimType] = useState(claimTypes[0].name);

  const [vendors, setVendor] = useState<Vendor[]>([]);
  const [matchedVendor, setMatchedVendor] = useState<Vendor>({
    id: 1,
    name: "",
    ytunnus: ""
  });
  const [comment, setComment] = useState("");
  const [tax, setTax] = useState<Tax>({ id: 1, name: "No Tax", percentage: 0 });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchVendorAndTax() {
      setIsLoading(true);
      try {
        const vendorData = await getVendors();
        const taxData = await getTaxRates();
        setVendor(vendorData.data.results);
        let calculatedTax = { id: 1, name: "No Tax", percentage: 0 };
        calculatedTax = fullData.vats
          ? taxData.data.results.find((t: Tax) => {
              return t.percentage === parseInt(fullData.vats[0].percent) / 100;
            })
          : calculatedTax;
        setTax(calculatedTax);
      } finally {
        setIsLoading(false);
      }
    }
    fetchVendorAndTax();
  }, []);

  useEffect(() => {
    let vendor: any = vendors.find((v: Vendor) => {
      return v.ytunnus === fullData.ytunnus;
    });
    if (vendor === undefined) {
      vendor = vendors[vendors.length - 1];
    }
    vendor && setMatchedVendor(vendor);
  }, [vendors]);

  if (!localStorage.getItem("BearerToken")) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <ScreenHeader
        onClickBack={() => {
          history.goBack();
        }}
        onClickDone={async () => {
          setIsLoading(true);
          try {
            const res = await postExpenses({
              ClaimType: claimType,
              ExpenseCategoryId: categories.category.id,
              ExpenseSubcategoryId: categories.subCategory.id,
              OtherPersonsIds: [],
              TaxRateId: tax.id,
              VendorId: matchedVendor.id,
              PurchasedAtUtc: new Date(fullData.date),
              Notes: comment,
              ExpenseStatus: "Created",
              LineItems: newExpenses,
              SavedFileId: fullData.savedFileId
            });
            res && setIsLoading(false);
            res &&
              history.push("/expenses/finalize", {
                data: history.location.state.fullData,
                expenseData: res.data.result,
                claimType
              });
          } catch (error) {
            setIsLoading(false);
            console.error({ error });
            window.confirm(`You have an error: ${error.message}`);
          }
        }}
        title="Details"
      />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              height: "fit-content",
              top: "175px",
              width: "90%",
              left: "5%",
              right: "5%",
              zIndex: 2
            }}
          >
            <DropdownInput
              iconContainerBackgroundColor={config.theme.claimtypeDropdown}
              height="46px"
              options={claimTypes}
              onChange={(value: string) => {
                setClaimType(value);
              }}
              iconName={getIconName(claimType)}
            />
          </div>
          <div style={{ padding: "12px 20px 20px 20px" }}>
            <div style={{ margin: "50px auto" }}>
              <TitleText>How does this relate to business?</TitleText>
              <DropdownInput
                iconContainerBackgroundColor={config.theme.subcategoryDropdown}
                height="46px"
                options={categories.subCategoryOptions}
                onChange={(value: string) => {
                  const selected: any = categories.category.expenseSubcategories.find(
                    (opt: { id: number; name: string }) => {
                      return opt.name === value;
                    }
                  );
                  selected &&
                    dispatchCategory({
                      type: SET_SUBCATEGORY,
                      payload: selected
                    });
                }}
                iconName={getIconName(categories.subCategory.name)}
              />
            </div>
            <div style={{ margin: "50px auto 15px auto" }}>
              <TitleText>Additional Comments</TitleText>
              <TextArea
                rows={10}
                placeholder="Comments"
                onChange={e => {
                  setComment(e.target.value);
                }}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
