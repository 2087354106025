const ICON_NAME: Record<string, string> = {
  // Category
  "Meeting Costs": "people",
  "Flight Costs": "airplanemode_active",
  "Accommodation Costs": "hotel",
  "Taxi Costs": "local_taxi",
  "Train, Bus or similar Costs": "commute",
  "Parking Costs": "local_parking",
  "Office Costs": "work",
  "Other Sales Costs": "dashboard",

  // Subcategory
  "Customer Breakfast": "restaurant_menu",
  "Customer Lunch": "restaurant_menu",
  "Customer Dinner": "restaurant_menu",
  "Customer Coffee": "emoji_food_beverage",
  "Customer Refreshments": "emoji_food_beverage",
  "Team Serving": "group",
  "Office Serving": "group",
  "Supplier Serving": "group",
  "Customer event": "event",
  "Team event": "event",
  "Supplier event": "event",
  "Event ticket": "event",
  "Working equipment": "gavel",
  "Working tools": "gavel",
  Memberships: "card_membership",

  // Claim type
  Reimbursements: "chrome_reader_mode",
  "Company Card": "credit_card"
};

/** Icon name for category, subcategory and claim type */
export function getIconName(subject: string) {
  return ICON_NAME[subject];
}
