import React, { ReactNode } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

export function getTranslatedContentStyle(
  top = 50,
  left = 50,
  noneTranslate: boolean
) {
  return {
    // position: 'absolute',
    top: `${top}%`,
    left: `${left}%`,
    transform: noneTranslate ? "" : `translate(${-left}%,${-top}%)`
  };
}

interface BaseModalProps {
  children: ReactNode;
  isOpen: boolean;
  contentWidth: number | string;
  top: number;
  left: number;
  noneTranslate: boolean;
}

function BaseModal({
  children,
  isOpen,
  contentWidth,
  top,
  left,
  noneTranslate
}: BaseModalProps) {
  return (
    <Modal open={isOpen} closeAfterTransition BackdropComponent={Backdrop}>
      <Fade in={isOpen}>
        <div
          style={{
            ...getTranslatedContentStyle(top, left, noneTranslate),
            position: "absolute",
            width: contentWidth,
            justifyContent: "center",
            alignItems: "center",
            outline: "none"
          }}
        >
          {children}
        </div>
      </Fade>
    </Modal>
  );
}

export default BaseModal;
