import React from "react";
import styled from "styled-components";
import config from "../assets/config";

const Typography = styled.div<{
  textColor?: string;
  size?: string;
  font?: string;
  textAlign?: string;
}>`
  color: ${props =>
    props.textColor ? props.textColor : config.theme.darkColor};
  font-size: ${props =>
    props.size ? props.size : config.theme.fontSizeNormal};
  font-family: ${props => (props.font ? props.font : config.theme.fontFamily)};
  text-align: ${props => (props.textAlign ? props.textAlign : "start")};
`;

export default function Text({
  textColor,
  size,
  font,
  children,
  textAlign
}: {
  textColor?: string;
  font?: string;
  size?: string;
  children: string | React.ReactNode;
  textAlign?: string;
}) {
  return (
    <Typography
      textColor={textColor}
      font={font}
      size={size}
      textAlign={textAlign}
      theme={{}}
    >
      {children}
    </Typography>
  );
}
