import React from "react";
import styled from "styled-components";
import config from "../assets/config";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Done from "@material-ui/icons/Done";
import Text from "../components/Text";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  padding: 46px 30px 30px 30px;
  background-color: ${config.theme.whiteColor};
  box-shadow: 0 2px 7px rgba(53, 64, 82, 0.06);
  margin-bottom: 36px;
`;

const Title = styled.div`
  margin: 10px 0;
`;

const SubTitle = styled.p`
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.iconColor};
  font-size: ${config.theme.fontFamily};
`;

interface ScreenHeaderProps {
  onClickBack?: () => void;
  onClickDone?: () => void;
  title?: string;
  subTitle?: string;
  children?: string | React.ReactNode;
}

function ScreenHeader({
  onClickBack,
  onClickDone,
  title,
  subTitle,
  children
}: ScreenHeaderProps) {
  return (
    <Wrapper>
      <Container>
        {onClickBack && (
          <ArrowBackIos
            style={{ height: 30, width: 30, color: config.theme.blue }}
            onClick={onClickBack}
          />
        )}
        {onClickDone && (
          <Done
            style={{ height: 30, width: 30, color: config.theme.blue }}
            onClick={onClickDone}
          />
        )}
      </Container>
      {children}
      {title && (
        <div style={{ margin: "32px 0 15px 0", order: 2 }}>
          <Text
            size={config.theme.fontSizeExtraBig}
            font={config.theme.fontFamilyBold}
          >
            {title}
          </Text>
        </div>
      )}
      {subTitle && (
        <div style={{ order: 3, marginBottom: 15 }}>
          <Text
            textColor={config.theme.iconColor}
            size={config.theme.fontSizeSmall}
          >
            {subTitle}
          </Text>
        </div>
      )}
    </Wrapper>
  );
}

export default ScreenHeader;
