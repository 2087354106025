import React, { Fragment } from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Expense from "../modules/Expenses/Expenses";
import ItemsScreen from "../modules/ItemsScreen/ItemsScreen";
import DetailScreen from "../modules/DetailScreen/DetailScreen";
import Scanner from "../modules/Scanner/Scanner";
import FinalizeScreen from "../modules/FinalizeScreen/FinalizeScreen";
import { ScannerProvider } from "../context/ScannerContext";
import { CategoryProvider } from "../context/CategoryContext";

export default function AddExpenseRoute({ match }: RouteComponentProps) {
  return (
    <CategoryProvider>
      <ScannerProvider>
        <Switch>
          <Fragment>
            <Route
              path={`${match.path}`}
              exact
              render={props => <Expense {...props} />}
            />
            <Route
              path={`${match.path}/scanner`}
              exact
              render={props => <Scanner {...props} />}
            />
            <Route
              path={`${match.path}/items`}
              exact
              render={props => <ItemsScreen {...props} />}
            />
            <Route
              path={`${match.path}/details`}
              exact
              render={props => <DetailScreen {...props} />}
            />
            <Route
              path={`${match.path}/finalize`}
              exact
              render={props => <FinalizeScreen {...props} />}
            />
          </Fragment>
        </Switch>
      </ScannerProvider>
    </CategoryProvider>
  );
}
