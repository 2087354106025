import React from "react";
import styled from "styled-components";
import config from "../../../assets/config";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const Title = styled.div`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.textColor};
`;

const SubTitle = styled.div`
  font-family: ${config.theme.fontFamilyMedium};
  font-size: ${config.theme.fontSizeNormal};
  color: ${config.theme.textColor};
`;

interface InfoRowProps {
  title: string;
  subTitle: string;
}

function InfoRow({ title, subTitle }: InfoRowProps) {
  return (
    <Container>
      <Title>{title}</Title>
      <div style={{ height: 10 }} />
      <SubTitle>{subTitle}</SubTitle>
    </Container>
  );
}

export default InfoRow;
