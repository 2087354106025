import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import NavTab from "../../components/NavTab";
import ScreenHeader from "../../components/ScreenHeader";
import ExpenseCard from "../../components/ExpenseCard";
import AddNewButton from "./sub_components/AddNewButton";
import SearchInput from "./sub_components/SearchInput";
import config from "../../assets/config";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { getExpenses } from "../../services/ScannerServices";
import { CircularProgress } from "@material-ui/core";

const Title = styled.div`
  font-family: ${config.theme.fontFamilyBold};
  font-size: ${config.theme.fontSizeExtraBig};
  color: ${config.theme.darkColor};
`;

const SubTitle = styled.div`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.iconColor};
  margin: 10px 0px;
`;

const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${config.theme.iconColor10};
`;

const Separator = styled.div`
  height: 15px;
`;

const ContentContainer = styled.div`
  padding: 0 25px 25px 25px;
  margin-top: 48px;
  margin-bottom: 50px;
`;

type ExpensesProps = RouteComponentProps & {};

export default function Expenses(props: ExpensesProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term: any) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    async function fetchExpenses() {
      setIsLoading(true);
      try {
        const res = await getExpenses();
        let sortedExpenses = res.data.results.sort((a: any, b: any) => {
          return -(parseInt(a.id) - parseInt(b.id));
        });
        setExpenses(sortedExpenses);
      } finally {
        setIsLoading(false);
      }
    }
    fetchExpenses();
  }, []);

  if (!localStorage.getItem("BearerToken")) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ height: "100%", minHeight: "100vh" }}>
      <Container>
        <ScreenHeader title={"Expenses"} subTitle={"These are your expenses"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <AddNewButton
              onClick={() => {
                props.history.push("/expenses/scanner");
              }}
            />
          </div>
        </ScreenHeader>
        <div
          style={{
            width: "80%",
            margin: "-60px auto 0 auto",
            overflowX: "visible"
          }}
        >
          <SearchInput
            onChangeSearch={(term: any) => {
              handleSearch(term);
            }}
          />
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 50
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <ContentContainer>
            {expenses &&
              expenses
                .filter((expense: any) => {
                  return expense.vendor.name.includes(searchTerm);
                })
                .map((expense: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <ExpenseCard
                        title={expense.vendor ? expense.vendor.name : ""}
                        status={expense.expenseStatus}
                        amount={expense.grossAmount}
                        currency={"€"}
                        icon={
                          <div
                            style={{
                              height: 45,
                              width: 45,
                              backgroundColor: `${config.theme.greyBackground}`,
                              borderRadius: 22.5
                            }}
                          />
                        }
                      />
                      <Separator />
                    </Fragment>
                  );
                })}
          </ContentContainer>
        )}
      </Container>
      <NavTab pathName={props.location.pathname} />
    </div>
  );
}
