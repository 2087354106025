import React, { ReactNode } from "react";
import styled from "styled-components";
import config from "../assets/config";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 9px 0px ${config.theme.shadowColor};
  border-radius: 10px;
  border: none;
  height: 95px;
  padding: 0px 10px;
  background-color: ${config.theme.whiteColor};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-family: ${config.theme.fontFamilySemiBold};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.darkFont};
`;

const Status = styled.div`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${props => props.color};
`;

const Amount = styled.div`
  font-family: ${config.theme.fontFamilySemiBold};
  font-size: ${config.theme.fontSizeSmall};
  color: ${props => props.color};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

interface ExpenseCardProps {
  title: string;
  status: string;
  amount: number;
  currency: string;
  icon: ReactNode;
}

function ExpenseCard({
  title,
  status,
  amount,
  currency,
  icon
}: ExpenseCardProps) {
  const amountText =
    amount < 0 ? `-${amount}${currency}` : `+${amount}${currency}`;
  return (
    <Container>
      <Row>
        {icon}
        <Column>
          <Title>{title}</Title>
          <Status
            color={
              amount > 0
                ? config.theme.positiveColor
                : config.theme.warningColor
            }
          >
            {status}
          </Status>
        </Column>
      </Row>

      <Amount
        color={
          amount > 0 ? config.theme.positiveColor : config.theme.warningColor
        }
      >
        {amountText}
      </Amount>
    </Container>
  );
}

export default ExpenseCard;
