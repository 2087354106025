import React from "react";
import styled from "styled-components";
import config from "../assets/config";

const Label = styled.label<{ textColor?: string }>`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${props => props.color};
  margin-left: 10px;
  color: ${props => props.textColor || config.theme.darkColor};
  margin-bottom: 15px;
  display: block;
`;

const Container = styled.div<{
  width?: string | number;
  height?: string | number;
  textColor?: string;
  backgroundColor?: string;
}>`
  width: ${props => props.width || "fit-content"};
  height: ${props => props.height || "fit-content"};
  background-color: ${props =>
    props.backgroundColor || config.theme.whiteColor};
  color: ${props => props.textColor || "black"};
  border-radius: 23px;
  box-shadow: 1px 1px 5px 1px ${config.theme.shadowColor};
  display: flex;
  font-size: ${config.theme.fontSizeSmall};
  font-family: ${config.theme.fontFamilyMedium};
  padding: 0 5px;
  justify-content: center;
  align-items: center;
`;

interface RoundedCardInterface {
  height?: number | string;
  width?: number | string;
  backgroundColor?: string;
  textColor?: string;
  label?: string;
  content: string;
}

export default function RoundedCard({
  width,
  height,
  textColor,
  backgroundColor,
  label,
  content
}: RoundedCardInterface) {
  return (
    <div>
      {label && <Label textColor={textColor}>{label}</Label>}
      <Container
        width={width}
        height={height}
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        {content}
      </Container>
    </div>
  );
}
