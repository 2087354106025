import React from "react";
import styled from "styled-components";
import config from "../../../assets/config";
import Add from "@material-ui/icons/Add";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

const Text = styled.div`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.blue};
`;

interface AddNewButtonProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function AddNewButton({ onClick }: AddNewButtonProps) {
  return (
    <Container onClick={onClick}>
      <Add style={{ color: config.theme.blue, height: 20, width: 20 }} />
      <Text>Create new</Text>
    </Container>
  );
}

export default AddNewButton;
