import React, { useContext, useReducer, ReactNode } from "react";

export const ADD_IMAGE = "ADD IMAGE";
export const RESET_IMAGE = "RESET IMAGE";

// @ts-ignore
let ScannerContext = React.createContext();

let initialState = {
  imageBase64: null
};

let reducer = (state: any, action: { type: string; payload: string }) => {
  switch (action.type) {
    case ADD_IMAGE:
      return { ...state, imageBase64: action.payload };

    case RESET_IMAGE:
      return initialState;
  }
};

export function ScannerProvider(props: { children: ReactNode }) {
  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <ScannerContext.Provider value={value}>
      {props.children}
    </ScannerContext.Provider>
  );
}

export function useScanner() {
  const { state, dispatch } = useContext(ScannerContext);
  return { scanner: state, dispatchScanner: dispatch };
}
