import React from 'react';
import styled from 'styled-components';
import config from '../../../assets/config';
import { Row } from '../../../components/Row';

const Container = styled.div`
  border-top: 1px solid ${config.theme.greyBorder};
  padding: 10px 0;
`;

const IconWrapper = styled.div<{ iconWrapperColor: string }>`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.iconWrapperColor};
  color: ${config.theme.whiteColor};
`;
const Text = styled.p`
  font-family: ${config.theme.fontFamilyMedium};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.iconColor};
  margin-left: 20px;
`;

type ReceiptItemProps = {
  item: string;
  iconWrapperColor?: string;
  icon: React.ReactNode;
};

export default function ReceiptItem({
  item,
  iconWrapperColor,
  icon
}: ReceiptItemProps) {
  return (
    <Container>
      <Row>
        <IconWrapper iconWrapperColor={iconWrapperColor || config.theme.blue}>
          {icon}
        </IconWrapper>
        <Text>{item}</Text>
      </Row>
    </Container>
  );
}
