export const resizeImage = (
  width: number,
  height: number
): { height: number; width: number } => {
  let aspectRatio = width / height;
  if (width < 1200 && height < 1200 && width < height) {
    console.log({ width, height });
    return { width, height };
  } else {
    if (aspectRatio > 1) {
      let newHeight = width;
      let newWidth = height;
      return resizeImage(newWidth, newHeight);
    } else {
      height = height - 50;
      width = Math.floor(height * aspectRatio);
      return resizeImage(width, height);
    }
  }
};
