import React from "react";
import NavTab from "../../components/NavTab";
import ScreenHeader from "../../components/ScreenHeader";
import styled from "styled-components";
import config from "../../assets/config";
import InfoRow from "./sub_components/InfoRow";
import { RouteComponentProps, Redirect } from "react-router-dom";

const Title = styled.div`
  font-family: ${config.theme.fontFamilyBold};
  font-size: ${config.theme.fontSizeExtraBig};
  color: ${config.theme.darkColor};
`;

const SubTitle = styled.div`
  font-family: ${config.theme.fontFamilyMedium};
  font-size: ${config.theme.fontSizeNormal};
  color: ${config.theme.textColor65};
  margin: 10px 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderContainer = styled.div`
  padding: 20px 25px;
  background-color: ${config.theme.whiteColor};
`;

const ContentContainer = styled.div`
  padding: 20px 25px 0px 25px;

  height: 100%;
  overflow: visible;
`;

const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${config.theme.iconColor10};
`;

type ProfileProps = RouteComponentProps & {};

export default function Profile(props: ProfileProps) {
  if (!localStorage.getItem('BearerToken')) {
    return <Redirect to="/" />
  }
  return (
    <Container>
      <ScreenHeader title={"Profile"} subTitle={"Tero Lappalainen"}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
          <div
            style={{
              position: "absolute",
              top: "8%",
              height: 60,
              width: 60,
              backgroundColor: "rgb(151, 165, 188)",
              borderRadius: 30
            }}
          />
        </div>
      </ScreenHeader>
      <ContentContainer>
        <InfoRow title="ROLE" subTitle="Sales Lead" />
        <InfoRow title="DEPARTMENT" subTitle="Sales & Distribution" />
        <InfoRow title="COST CENTER" subTitle="Sales & Distribution" />
        <InfoRow title="DIRECT MANAGER" subTitle="Jamie Lannister" />
        <InfoRow title="DEPARTMENT HEAD" subTitle="Karoliina Laakso" />
      </ContentContainer>
      <NavTab pathName={props.location.pathname} />
    </Container>
  );
}
