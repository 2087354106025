import React, { useReducer, useContext } from 'react';

export const SET_CATEGORY = "SET CATEGORY";
export const SET_SUBCATEGORY_OPTIONS = "SET SUBCATEGORY OPTIONS"
export const SET_SUBCATEGORY = "SET SUBCATEGORY";

//@ts-ignore
let CategoryContext = React.createContext();

interface SubCategory {
    id: number;
    name: string
}

interface Category {
    id: number;
    name: string;
    expenseSubcategories: SubCategory[];
}

let initialState = {
    category: {
        id: null,
        name: '',
        expenseSubcategories: []
    },
    subCategoryOptions: []
    , subCategory: {
        id: null,
        name: ''
    }
}

let reducer = (state: any, action: { type: string, payload: any }) => {
    switch (action.type) {
        case SET_CATEGORY:
            return {
                ...state,
                category: action.payload
            }
        case SET_SUBCATEGORY_OPTIONS:
            return {
                ...state,
                subCategoryOptions: action.payload
            }
        case SET_SUBCATEGORY:
            return {
                ...state,
                subCategory: action.payload
            }

        default:
            return initialState;
    }
}

export function CategoryProvider(props: { children: React.ReactNode }) {
    let [state, dispatch] = useReducer(reducer, initialState);
    let value = { state, dispatch };

    return (
        <CategoryContext.Provider value={value}>
            {props.children}
        </CategoryContext.Provider>
    )
}

export function useCategory() {
    const { state, dispatch } = useContext(CategoryContext);
    return {
        categories: state,
        dispatchCategory: dispatch
    }
}





