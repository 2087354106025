import React from 'react';
import styled from 'styled-components';
import config from '../assets/config';

const Container = styled.div<{
  height?: string | number;
  textColor?: string;
  borderColor: string;
}>`
  width: fit-content;
  height: ${props => props.height || 'fit-content'};
  background-color: transparent;
  color: ${props => props.textColor || 'black'};
  border-radius: 10px;
  border: 1px solid ${props => props.borderColor};
  padding: 10px;
`;

interface RoundedCardInterface {
  height?: number | string;
  borderColor: string;
  textColor?: string;
  content: string;
}

export default function PriceTag({
  height,
  textColor,
  borderColor,
  content
}: RoundedCardInterface) {
  return (
    <div>
      <Container
        height={height}
        textColor={textColor}
        borderColor={borderColor}
      >
        {content}
      </Container>
    </div>
  );
}
