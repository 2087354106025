import React, { useState, useEffect } from "react";
// import { Button } from '@material-ui/core';
import { RouteComponentProps } from "react-router-dom";
import BaseModal from "../../components/BaseModal";
import config from "../../assets/config";
import styled from "styled-components";
import { Check } from "@material-ui/icons";

interface SucceedScreenInterface extends RouteComponentProps {
  shouldOpen: boolean;
}

const Title = styled.h4`
  margin: 20px 0;
  font-family: ${config.theme.fontFamilyBold};
  font-size: ${config.theme.fontSizeBig};
  color: ${config.theme.darkColor};
`;

const SubTitle = styled.p`
  margin: 20px auto;
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.darkColor};
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  background-color: ${config.theme.blue50};
  color: ${config.theme.whiteColor};
  text-align: center;
  border: none;
  outline: none;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 15px 15px;
`;

const ButtonText = styled.p`
  color: ${config.theme.whiteColor};
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
`;

export default function SucceedScreen({
  shouldOpen,
  history
}: SucceedScreenInterface) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(shouldOpen);
  }, [shouldOpen]);
  return (
    <BaseModal
      isOpen={isOpen}
      contentWidth="100%"
      top={50}
      left={50}
      noneTranslate={false}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "85%",
          height: "55%",
          textAlign: "center",
          margin: "0 auto",
          borderRadius: "15px"
        }}
      >
        <div style={{ padding: "20px" }}>
          <div
            style={{
              height: 140,
              width: 140,
              borderRadius: 70,
              border: "20px solid #E2F4E0",
              backgroundColor: config.theme.green,
              color: config.theme.whiteColor,
              margin: "20px auto",
              display: "flex",
              justifyContent: "center",
              fontSize: config.theme.fontSizeBig,
              alignItems: "center"
            }}
          >
            <Check fontSize="large" />
          </div>
          <Title>Approved</Title>
          <SubTitle>
            That's it! The amount will be transfered to your next payroll
          </SubTitle>
        </div>
        <Button
          onClick={() => {
            setIsOpen(false);
            history.push("/expenses");
          }}
        >
          <ButtonText>Okay</ButtonText>
        </Button>
      </div>
    </BaseModal>
  );
}
