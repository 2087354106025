import React, { useState, useEffect } from "react";
import moment from "moment";
import ScreenHeader from "../../components/ScreenHeader";
import { RouteComponentProps, Redirect } from "react-router-dom";
import ExpenseItem from "../../components/ExpenseItem";
import DropdownInput from "../../components/DropdownInput";
import styled from "styled-components";
import config from "../../assets/config";
import { getIconName } from "../../utils/iconName";
import { Row, CustomRow, RowWithIconText } from "../../components/Row";
import RoundedCard from "../../components/RoundedCard";
import EyeIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import AddIcon from "@material-ui/icons/Add";
import PriceTag from "../../components/PriceTag";
import Text from "../../components/Text";
import { useScanner } from "../../context/ScannerContext";
import ScannerImageModal from "../../components/ScannerImageModal";
import { getExpenseCategories } from "../../services/ScannerServices";
import { CircularProgress } from "@material-ui/core";
import {
  useCategory,
  SET_CATEGORY,
  SET_SUBCATEGORY_OPTIONS,
  SET_SUBCATEGORY
} from "../../context/CategoryContext";

interface ItemsScreenInterface extends RouteComponentProps {}

const Container = styled.div<{ isModalShown: boolean }>`
  background: ${config.theme.blueBackground};
  min-height: 100vh;
  padding-bottom: 30px;
  overflow-y: ${props => (props.isModalShown ? "hidden" : "scroll")};
`;

const TitleText = styled.h6`
  margin: 0 0 10px 10px;
  font-family: ${config.theme.fontFamily};
  color: ${config.theme.iconColor};
  font-size: ${config.theme.fontSizeSmall};
`;

const NormalText = styled.p`
  margin: 0;
  font-size: ${config.theme.fontSizeNormal};
  font-family: ${config.theme.fontFamilyMedium};
`;

export default function ItemsScreen({ history }: ItemsScreenInterface) {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { categories, dispatchCategory } = useCategory();
  useEffect(() => {
    async function fetchExpenseCategories() {
      setIsLoading(true);
      try {
        const res = await getExpenseCategories();
        setCategoryOptions(res.data.results);
        dispatchCategory({ type: SET_CATEGORY, payload: res.data.results[0] });
        dispatchCategory({
          type: SET_SUBCATEGORY_OPTIONS,
          payload: res.data.results[0].expenseSubcategories
        });
        dispatchCategory({
          type: SET_SUBCATEGORY,
          payload: res.data.results[0].expenseSubcategories[0]
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchExpenseCategories();
  }, []);
  const receiptData = history.location.state
    ? history.location.state.data
    : null;
  let [isOpen, setIsOpen] = useState(false);
  const { scanner } = useScanner();
  const handleViewScanner = () => {
    if (!scanner.imageBase64) {
      alert("Cannot access image");
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };
  const renderReceipt = (receipt: any) => {
    return (
      <div>
        {receipt.expenses && (
          <div>
            {receipt.expenses.map(
              (expense: { item: string; price: number }, index: number) => (
                <ExpenseItem
                  item={expense.item}
                  amount={expense.price}
                  currency={"€"}
                  key={index}
                />
              )
            )}
          </div>
        )}
        {receipt.paymentInfo && (
          <div style={{ margin: "30px 0" }}>
            <TitleText>Add the total amount</TitleText>
            <CustomRow justifyContent="space-between">
              <Text
                size={config.theme.fontSizeMedium}
                font={config.theme.fontFamilyMedium}
              >
                Total amount
              </Text>
              <PriceTag
                content={`${receipt.paymentInfo.amount}€`}
                textColor={config.theme.blue25}
                borderColor={config.theme.blue25}
              />
            </CustomRow>
          </div>
        )}
        {receipt.vats && (
          <div>
            <TitleText>Choose the tax</TitleText>
            {receipt.vats.map(
              (
                vat: {
                  percent: any;
                  amount: number;
                },
                index: number
              ) => {
                const { amount, percent } = vat;

                return (
                  <ExpenseItem
                    item={`ALV ${percent}%`}
                    amount={amount}
                    currency={"€"}
                    key={index}
                  />
                );
              }
            )}
          </div>
        )}
      </div>
    );
  };

  if (!localStorage.getItem("BearerToken")) {
    return <Redirect to="/" />;
  }

  return (
    <Container isModalShown={isOpen}>
      <ScreenHeader
        onClickBack={() => {
          history.goBack();
        }}
        onClickDone={() => {
          history.push("/expenses/details", { fullData: receiptData });
        }}
        title="Items"
        subTitle="Add the details of your expense"
      />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ padding: "20px" }}>
          <CustomRow justifyContent="space-between">
            <div style={{ marginRight: "10px", width: "65%" }}>
              <DropdownInput
                label="Category"
                colorLabel={config.theme.iconColor}
                iconContainerBackgroundColor={config.theme.categoryDropdown}
                iconName={getIconName(categories.category.name)}
                options={categoryOptions}
                onChange={(value: string) => {
                  const selected: any = categoryOptions.find(
                    (opt: {
                      name: string;
                      id: number;
                      expenseSubcategories: [];
                    }) => {
                      return opt.name === value;
                    }
                  );
                  selected &&
                    dispatchCategory({ type: SET_CATEGORY, payload: selected });
                  selected &&
                    dispatchCategory({
                      type: SET_SUBCATEGORY_OPTIONS,
                      payload: selected.expenseSubcategories
                    });
                  selected &&
                    dispatchCategory({
                      type: SET_SUBCATEGORY,
                      payload: selected.expenseSubcategories[0]
                    });
                }}
              />
            </div>
            <div style={{ width: "32%" }}>
              <RoundedCard
                label="Date"
                content={
                  receiptData && receiptData.date
                    ? moment(receiptData.date).format("DD.MM.YYYY")
                    : moment().format("DD.MM.YYYY")
                }
                height="46px"
                width="100%"
                textColor={config.theme.iconColor}
              />
            </div>
          </CustomRow>
          <div style={{ margin: "30px 0 0 0" }}>
            <CustomRow justifyContent="space-between">
              <div onClick={handleViewScanner}>
                <RowWithIconText
                  icon={<EyeIcon fontSize="small" color="disabled" />}
                  text="Receipt"
                  color={config.theme.disabledColor}
                />
              </div>
              <RowWithIconText
                icon={<AddIcon fontSize="small" color="primary" />}
                text="Add item"
                color={config.theme.blue50}
              />
            </CustomRow>
          </div>
          {receiptData ? (
            renderReceipt(receiptData)
          ) : (
            <div style={{ textAlign: "center", margin: "30px 0" }}>
              <Text textAlign="center">You have no items</Text>
            </div>
          )}
        </div>
      )}
      <ScannerImageModal
        isOpen={isOpen}
        scannerImage={scanner.imageBase64}
        closeModal={() => setIsOpen(false)}
      />
    </Container>
  );
}
