import apiUtils from '../utils/apiUtils';

interface BodyType {
  content: string;
  docType: string;
  user: {
    role: string;
    username: string;
  };
}

interface ExpenseBody {
  ClaimType: string;
  ExpenseCategoryId: number;
  ExpenseSubcategoryId: number;
  OtherPersonsIds: number[];
  TaxRateId: number;
  VendorId: number;
  PurchasedAtUtc: Date;
  Notes: string | null;
  ExpenseStatus: string;
  LineItems: Array<{ Name: string, GrossAmount: number }>;
  SavedFileId: number
}

export function scanReceipt(body: FormData) {
  return apiUtils.postFiles(
    '/upload-jpeg',
    body
  );
}

export async function login(
  username: string | string[],
  password: string | string[],
) {
  const url = `/auth?UserName=${username}&Password=${password}`
  return await apiUtils.post(url, { username, password })
}

export async function getExpenses() {
  const url = `/user-expenses`
  return await apiUtils.get(url);
}

export async function getExpenseCategories() {
  const url = `/expense-categories`;
  return await apiUtils.get(url);
}

export async function postExpenses(
  body: ExpenseBody
) {
  const url = `/user-expenses`;
  return await apiUtils.post(url, body)
}

export async function getVendors() {
  const url = `/vendors`;
  return await apiUtils.get(url);
}

export async function getTaxRates() {
  const url = `/tax-rates`;
  return await apiUtils.get(url);
}

export async function postFinalizedExpenses(
  ExpenseId: number
) {
  const url = `/user-expenses/${ExpenseId}/finalize`
  return await apiUtils.post(url, { ExpenseId })
}