import React, { useState } from "react";
import styled from "styled-components";
// import Reorder from "@material-ui/icons/Reorder";
import Icon from "@material-ui/core/Icon";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import config from "../assets/config";

const Container = styled.div<{ height?: string | number }>`
  display: flex;
  flex-direction: row;
  height: ${props => props.height || "46px"};
  border-radius: 23px;
  overflow: hidden;
  box-shadow: 1px 1px 5px 1px ${config.theme.shadowColor};
  align-items: center;
  padding-right: 10px;
  background-color: ${config.theme.whiteColor};
`;

const IconContainer = styled.div<{ iconContainerBackgroundColor?: string }>`
  display: flex;
  width: 46px;
  height: 46px;
  background-color: ${props =>
    props.iconContainerBackgroundColor || config.theme.blue};
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Label = styled.label`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${props => props.color};
  margin-left: 10px;
  display: block;
  margin-bottom: 15px;
`;

const SelectContainer = styled.div`
  flex: 3;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  & select {
    font-family: ${config.theme.fontFamilyMedium};
    font-size: ${config.theme.fontSizeSmall};
    border: none;
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
  }
  & select::-ms-expand {
    display: none;
  }
`;

interface DropdownInputProps {
  label?: string;
  value?: string;
  onChange?: Function;
  height?: string;
  colorLabel?: string;
  iconContainerBackgroundColor?: string;
  iconName: string;
  // options: Array<{ name: string; expenseSubcategories: [subOptions] }>;
  options?: Array<{ name: string }>;
  subOptions?: Array<{ id: number; name: string }>;
}

function DropdownInput({
  label,
  height,
  colorLabel,
  iconContainerBackgroundColor,
  iconName,
  onChange,
  options,
  subOptions
}: DropdownInputProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {label && (
        <Label color={colorLabel || config.theme.iconColor}>{label}</Label>
      )}
      <Container height={height}>
        <IconContainer
          iconContainerBackgroundColor={iconContainerBackgroundColor}
        >
          <Icon style={{color: 'white'}}>{iconName}</Icon>
        </IconContainer>
        <SelectContainer>
          {options && (
            <select
              style={{ height: "100%", width: "100%", paddingLeft: 10 }}
              onChange={e => {
                const value = e.target.value;
                onChange && onChange(value);
              }}
            >
              {options.map((op, index) => (
                <option key={index} value={op.name}>
                  {op.name}
                </option>
              ))}
            </select>
          )}

          <div style={{ position: "absolute", right: 0 }}>
            <KeyboardArrowDown
              style={{ height: 30, width: 30, color: config.theme.lightGrey50 }}
            />
          </div>
        </SelectContainer>
      </Container>
    </div>
  );
}

export default DropdownInput;
