import React, { useState } from "react";
import styled from "styled-components";
import config from "../../assets/config";
import login from "../../assets/images/login.png";
import CircularProgess from "@material-ui/core/CircularProgress";
import { login as loginFunction } from "../../services/ScannerServices";

const Container = styled.div`
  background: linear-gradient(#48687e, #547c9f, #5985af);
  color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  overflow-y: scroll !important;
`;

const IntroArea = styled.div`
  flex: 1;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 100px 0;
`;

const FormArea = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-bottom: 30px;
`;

const InputGroup = styled.div`
  width: 70%;
  margin: 10px auto;
  flex-grow: 1;
  flex-basis: 1;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  width: 100%;
  display: block;
  border: 0;
  outline: 0;
  /* height: 40px; */
  border-radius: 0;
  border-bottom: 1px solid ${config.theme.whiteColor};
  background: transparent;
  color: ${config.theme.whiteColor};
  font-size: ${config.theme.fontSizeSmall};
  margin: 5px 0;
  padding: 0 0 5px 5px;
`;

const Label = styled.label`
  display: block;
`;

const Title = styled.div`
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeNormal};
  color: ${config.theme.whiteColor};
  margin-bottom: 72px;
  padding: 0 65px 0 65px;
`;

const Button = styled.button`
  padding: 10px;
  border: 1px solid ${config.theme.whiteColor};
  border-radius: 5px;
  max-height: 38px;
  color: ${config.theme.whiteColor};
  width: 100%;
  margin: 10px 0;
  background-color: transparent;
  font-size: ${config.theme.fontSizeSmall};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
`;

export default function Login(props: any) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <IntroArea>
        <Title>Get expenses approved in seconds</Title>
        <div
          style={{
            width: 150,
            height: 150
          }}
        >
          <Image src={login} />
        </div>
      </IntroArea>
      <FormArea>
        <InputGroup>
          <Label>Email</Label>
          <Input
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          <Label>Password</Label>
          <Input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          {loading ? (
            <Button>
              <CircularProgess size={20} />
            </Button>
          ) : (
            <Button
              onClick={async () => {
                setLoading(true);
                try {
                  const res = await loginFunction(username, password);
                  localStorage.setItem("BearerToken", res.data.bearerToken);
                  props.history.push("/expenses");
                } catch (err) {
                  console.log(err);
                  alert("Unable to login. Please try again");
                  setLoading(false);
                }
              }}
            >
              Login
            </Button>
          )}
        </InputGroup>
      </FormArea>
    </Container>
  );
}
