import React, { useEffect, useState, useRef, useCallback } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import styled from "styled-components";
import config from "../../assets/config";
import Clear from "@material-ui/icons/Clear";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import CircularProgress from "@material-ui/core/CircularProgress";
import CaptureImageButton from "./sub_components/CaptureImageButton";
import { scanReceipt } from "../../services/ScannerServices";
import { resizeImage } from "../../utils/image";
import { useScanner, ADD_IMAGE } from "../../context/ScannerContext";

type ScannerProps = RouteComponentProps & {};

function detectmob() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

const medias = {
  audio: false,
  video: {
    facingMode: detectmob()
      ? {
        exact: "environment"
      }
      : "user"
  }
};

const toBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      reader.result ? resolve(reader.result.toString()) : reject("No result");
    };
    reader.onerror = error => reject(error);
  });

function Scanner(props: ScannerProps) {
  const video = useRef<HTMLVideoElement>(null);
  const currentStream = useRef<MediaStream>(null);
  const { dispatchScanner } = useScanner();

  let [isLoading, setIsLoading] = useState(false);

  const successCallback = useCallback(function _successCallback(stream: any) {
    video.current && (video.current.srcObject = stream);
    (currentStream as any).current = stream;
    video.current && video.current.play();
  }, []);

  useEffect(
    () =>
      function stopCamera() {
        if (currentStream.current) {
          currentStream.current.getTracks().forEach(track => {
            track.stop();
          });
        }
      },
    []
  );

  function errorCallback(err: any) {
    console.error({ err });
  }

  async function handleScanImage(file: Blob) {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('img', file);
      let res = await scanReceipt(data);
      setIsLoading(false);
      toBase64(file)
        .then((data) => {
          dispatchScanner({ type: ADD_IMAGE, payload: data })
        })
      props.history.push("/expenses/items", { data: res.data.result });
      // turnOffCamera();
    } catch (error) {
      setIsLoading(false);
      console.error({ error });
      const confirmOption = window.confirm(
        `Something went wrong. ${
        error.response ? "" : error.message
        } Do you want to go to the next step?`
      );
      if (confirmOption) {
        props.history.push("/expenses/items");
      } else {
        return;
      }
    }
  }

  function handleTakePhoto(video: any) {
    const { height, width } = resizeImage(video.videoWidth, video.videoHeight);
    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    let context = canvas.getContext("2d");
    if (context) {
      context.drawImage(video, 0, 0, width, height);
    }

    getPngDimensions(canvas.toDataURL("image/jpeg"))
      .then(({ newFile }) => {
        handleScanImage(newFile);
      })
  }

  function getPngDimensions(base64String: string) {
    return new Promise<{ width: number; height: number; newFile: Blob }>(
      (resolve, reject) => {
        const img = new Image();
        img.onload = function () {
          let height: number = img.width,
            width: number = img.height
          // Receipt only accept portrait image and its height and width should not extend 1200
          const newImageSize = resizeImage(img.width, img.height);
          height = newImageSize.height;
          width = newImageSize.width;

          // Resize image file
          let canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              0,
              0,
              canvas.width,
              canvas.height
            );
          }
          canvas.toBlob(blob => {
            resolve({ width, height, newFile: blob || new Blob })
          }, "image/jpeg")

        };
        img.onerror = function (error) {
          reject(error);
        };
        img.src = base64String;
      }
    );
  }

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia(medias)
      .then(successCallback)
      .catch(errorCallback);
  }, [successCallback, isLoading]);

  const Container = styled.div`
    background-color: ${config.theme.cameraBackgroundColor};
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
  `;

  const HeaderContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    width: 100%;
  `;

  const Video = styled.video`
    height: 100%;
    width: calc(100% - 40px);
    overflow: hidden;
    padding: 0;
    margin: 0 auto 30px auto;
    object-fit: contain;
  `;

  const FooterContainer = styled.div`
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-end;
    position: fixed;
    bottom: 0;
    width: 100%;
  `;

  const PhotoButtonContainer = styled.div`
    flex: 1;
    transform: translate(${window.innerWidth / 2 - 25}px, 0);
  `;

  if (!localStorage.getItem('BearerToken')) {
    return <Redirect to="/" />
  }

  return (
    <Container>
      <HeaderContainer>
        <Clear
          onClick={() => {
            props.history.goBack();
          }}
          style={{
            height: 40,
            width: 40,
            color: config.theme.whiteColor
          }}
        />
      </HeaderContainer>

      <Video ref={video} autoPlay muted playsInline />

      <FooterContainer>
        <PhotoButtonContainer>
          {isLoading ? (
            <CircularProgress style={{ color: config.theme.whiteColor }} />
          ) : (
              <CaptureImageButton
                onClick={() => {
                  handleTakePhoto(video.current);
                }}
              />
            )}
        </PhotoButtonContainer>
        <InsertPhoto
          style={{
            height: 45,
            width: 45,
            color: config.theme.whiteColor,
            marginRight: 20
          }}
        />
        <input
          type="file"
          accept="image/*"
          id="single"
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (files) {
              const file: File = files[0];

              toBase64(file)
                .then(getPngDimensions)
                .then(({ newFile }) => {
                  handleScanImage(newFile);
                });

            }
          }}
          style={{
            height: 45,
            width: 45,
            opacity: 0,
            position: "absolute",
            right: 20
          }}
        />
      </FooterContainer>
    </Container>
  );
}

export default Scanner;
