import React from "react";
import config from "../assets/config";
import BaseModal from "./BaseModal";
import { Close } from "@material-ui/icons";

interface ScannerImageModalInterface {
  isOpen: boolean;
  scannerImage: string;
  closeModal: () => void;
}

export default function ScannerImageModal({
  isOpen,
  scannerImage,
  closeModal
}: ScannerImageModalInterface) {
  return (
    <BaseModal
      isOpen={isOpen}
      contentWidth="100%"
      top={50}
      left={50}
      noneTranslate={false}
    >
      <div
        style={{
          backgroundColor: "transparent",
          width: "90%",
          height: "80vh",
          textAlign: "center",
          margin: "0 auto",
          borderRadius: "15px",
          overflowY: "scroll"
        }}
      >
        <img
          src={scannerImage}
          alt="Receipt"
          width="100%"
          style={{ minHeight: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 20,
            height: 40,
            width: 40,
            border: `3px solid ${config.theme.blueHeaderIcon}`,
            borderRadius: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            left: "50%",
            transform: "translateX(-50%)"
          }}
          onClick={closeModal}
        >
          <Close
            style={{ height: 20, width: 20, color: `${config.theme.blueHeaderIcon}` }}
          />
        </div>
      </div>
    </BaseModal>
  );
}
