import React, { useState, useEffect } from "react";
import ScreenHeader from "../../components/ScreenHeader";
import { RouteComponentProps, Redirect } from "react-router-dom";
import SucceedModal from "../SucceedScreen/SucceedScreen";
import { Container } from "../../components/StyledComponents";
import styled from "styled-components";
import config from "../../assets/config";
import ReceiptContainer from "./sub-components/ReceiptContainer";
import ReceiptItem from "./sub-components/ReceiptItem";
import { CustomRow, Row } from "../../components/Row";
import { useScanner } from "../../context/ScannerContext";
import ScannerImageModal from "../../components/ScannerImageModal";
import moment from "moment";
import { postFinalizedExpenses } from "../../services/ScannerServices";
import { CircularProgress, Icon } from "@material-ui/core";
import { getVendors } from "../../services/ScannerServices";
import { getIconName } from "../../utils/iconName";
import { AttachFile } from "@material-ui/icons";

interface FinalizeScreenInterface extends RouteComponentProps {}

const Text = styled.p<{ size?: string; font?: string }>`
  font-family: ${props => props.font || config.theme.fontFamilyMedium};
  font-size: ${props => props.size || config.theme.fontSizeSmall};
  text-align: center;
  color: ${props => props.color || config.theme.darkColor};
  margin: 12px 0;
`;

const UnderScoreText = styled(Text)`
  /* border-bottom: 2px solid ${config.theme.greyBorder}; */
  text-decoration: underline;
`;

const ReceiptContent = styled.div`
  padding: 20px;
`;

const Button = styled.div`
  background-color: transparent;
  font-family: ${config.theme.fontFamily};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.iconColor};
  margin: 0 auto;
  text-align: center;
  align-content: center;
`;

export default function FinalizeScreen(props: FinalizeScreenInterface) {
  const [shouldOpen, setShouldOpen] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const receiptData = props.location.state.data;
  const { expenseData, claimType } = props.location.state;
  const [businessName, setBusinessName] = useState("");

  useEffect(() => {
    async function fetchVendors() {
      setIsLoading(true);
      try {
        const res = await getVendors();
        const newBusinessName = res.data.results.find((ven: any) => {
          return ven.ytunnus === expenseData.vendor.ytunnus;
        });
        setBusinessName(newBusinessName.name && "Unknown vendor");
      } finally {
        setIsLoading(false);
      }
    }
    fetchVendors();
  }, []);
  const { scanner } = useScanner();

  const handleViewScanner = () => {
    if (!scanner.imageBase64) {
      alert("Cannot access image");
      setIsOpenImage(false);
    } else {
      setIsOpenImage(true);
    }
  };
  if (!localStorage.getItem("BearerToken")) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <ScreenHeader
        onClickBack={() => {
          props.history.goBack();
        }}
        onClickDone={async () => {
          setIsLoading(true);
          try {
            const finializeResponse = await postFinalizedExpenses(
              expenseData.id
            );
            finializeResponse && setIsLoading(false);
            finializeResponse && setShouldOpen(true);
          } catch (error) {
            setIsLoading(false);
            console.error({ error });
            window.confirm(`You have an error: ${error.message}`);
          }
        }}
        title="Finalize"
        subTitle="You're almost ready"
      />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Text color={config.theme.iconColor}>Are these correct?</Text>
          <ReceiptContainer>
            <ReceiptContent>
              <div style={{ margin: "20px auto 10px auto" }}>
                <Text size={config.theme.fontSizeMedium}>{businessName}</Text>
              </div>
              <CustomRow justifyContent="space-between">
                <div>
                  <Text color={config.theme.iconColor}>Date</Text>
                  <Text size={config.theme.fontSizeMedium}>In total</Text>
                </div>
                <div>
                  <Text color={config.theme.iconColor}>
                    {moment(receiptData.date).format("DD.MM.YYYY") ||
                      "Cannot detect date"}
                  </Text>
                  <Text size={config.theme.fontSizeMedium}>
                    {receiptData.paymentInfo && receiptData.paymentInfo.amount}€
                  </Text>
                </div>
              </CustomRow>
              <div style={{ height: "20px" }} />
              <div>
                <ReceiptItem
                  item={`${claimType}`}
                  icon={<Icon fontSize="small">{getIconName(claimType)}</Icon>}
                  iconWrapperColor={config.theme.claimtypeDropdown}
                />
                <ReceiptItem
                  item={`${expenseData.expenseCategory.name}`}
                  icon={
                    <Icon fontSize="small">
                      {getIconName(expenseData.expenseCategory.name)}
                    </Icon>
                  }
                  iconWrapperColor={config.theme.categoryDropdown}
                />
                <ReceiptItem
                  item={`${expenseData.expenseSubcategory.name}`}
                  icon={
                    <Icon fontSize="small">
                      {getIconName(expenseData.expenseSubcategory.name)}
                    </Icon>
                  }
                  iconWrapperColor={config.theme.subcategoryDropdown}
                />
              </div>
              <div onClick={handleViewScanner}>
                <Row>
                  <AttachFile
                    fontSize="default"
                    style={{ color: config.theme.iconColor }}
                  />
                  <UnderScoreText color={config.theme.iconColor}>
                    IMG_26082019
                  </UnderScoreText>
                </Row>
              </div>
              {/* <div>
                    <Button>
                      <Text
                        font={config.theme.fontFamily}
                        color={config.theme.iconColor}
                      >
                        <span style={{ marginRight: 5, marginTop: 10 }}>
                          <Edit
                            fontSize="small"
                            style={{
                              color: config.theme.iconColor
                            }}
                          />
                        </span>
                        Edit
                      </Text>
                    </Button>
                  </div> */}
            </ReceiptContent>
          </ReceiptContainer>
          <SucceedModal shouldOpen={shouldOpen} {...props} />
          <ScannerImageModal
            isOpen={isOpenImage}
            scannerImage={scanner.imageBase64}
            closeModal={() => setIsOpenImage(false)}
          />
        </>
      )}
    </Container>
  );
}
