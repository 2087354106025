import React from "react";
import styled from "styled-components";
import config from "../assets/config";
import Edit from "@material-ui/icons/Edit";

interface ExpenseItemProps {
  item: string;
  amount: number | string;
  currency: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  height: 75px;
  box-shadow: 0px 2px 9px 0px ${config.theme.shadowColor};
  padding: 0px 15px;
  align-items: center;
  justify-content: space-between;
  background-color: ${config.theme.whiteColor};
  margin: 15px 0;
  border: none;
`;

const ItemName = styled.div`
  font-family: ${config.theme.fontFamilyMedium};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.darkFont};
  margin-left: 10px;
`;

const Amount = styled.div`
  font-family: ${config.theme.fontFamilySemiBold};
  font-size: ${config.theme.fontSizeSmall};
  color: ${config.theme.darkFont};
  justify-self: flex-end;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function ExpenseItem({ item, amount, currency }: ExpenseItemProps) {
  const amountText =
    amount < 0 ? `-${amount}${currency}` : `+${amount}${currency}`;
  return (
    <Container>
      <Row>
        <Edit style={{ color: config.theme.iconColor }} />
        <ItemName>{item}</ItemName>
      </Row>
      <Amount>{amountText}</Amount>
    </Container>
  );
}

export default ExpenseItem;
