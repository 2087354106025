import React from 'react';
import styled from 'styled-components';
import config from '../../../assets/config';
import ReceiptIcon from '@material-ui/icons/Receipt';

const Container = styled.div`
  width: 90%;
  height: fit-content;
  min-height: 300px;
  padding: 0;
  margin: 1px auto;
  border-radius: 20px;
  background-color: ${config.theme.whiteColor};
  box-shadow: 0.5px 2px 4px 1px ${config.theme.shadowColor};
  z-index: 1;
`;

const ReceiptArea = styled.div`
  height: 78px;
  width: 78px;
  transform: translate(0, 50%);
  overflow: hidden;
  background-color: ${config.theme.whiteColor};
  margin: 0 auto;
  z-index: 11;
  border-radius: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ReceiptIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${config.theme.lightGrey};
  height: 56px;
  width: 56px;
  border-radius: 28px;
`;

export default function ReceiptContainer({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <div>
      <ReceiptArea>
        <ReceiptIconContainer>
          <ReceiptIcon
            style={{ width: 17, height: 47, color: config.theme.iconColor }}
          />
        </ReceiptIconContainer>
      </ReceiptArea>
      <Container>{children}</Container>
    </div>
  );
}
