import React from "react";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter, Route } from "react-router-dom";
import Montserrat from "./assets/fonts/Montserrat/Montserrat-Regular.ttf";
import MontserratLight from "./assets/fonts/Montserrat/Montserrat-Light.ttf";
import MontserratBold from "./assets/fonts/Montserrat/Montserrat-Bold.ttf";
import MontserratSemiBold from "./assets/fonts/Montserrat/Montserrat-SemiBold.ttf";
import MontserratMedium from "./assets/fonts/Montserrat/Montserrat-Medium.ttf";
import Login from "./modules/Auth/Login";
import ExpenseRoute from "./route-wrapper/AddExpenseRoute";
import Profile from "./modules/Profile/Profile";
import "./App.css";

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Montserrat';
        src: url(${Montserrat}) format('opentype');
    }
    @font-face {
        font-family: 'MontserratLight';
        src: url(${MontserratLight}) format('opentype');
    }
    @font-face {
        font-family: 'MontserratBold';
        src: url(${MontserratBold}) format('opentype');
    }
    @font-face {
        font-family: 'MontserratSemiBold';
        src: url(${MontserratSemiBold}) format('opentype');
    }
     @font-face {
        font-family: 'MontserratMedium';
        src: url(${MontserratMedium}) format('opentype');
    }
    select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    }
`;

function App() {
  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        height: "100%"
      }}
    >
      <GlobalStyles />
      <BrowserRouter>
        <Route exact path="/" render={props => <Login {...props} />} />
        <Route path="/expenses" render={props => <ExpenseRoute {...props} />} />
        <Route exact path="/profile" render={props => <Profile {...props} />} />
      </BrowserRouter>
    </div>
  );
}

export default App;
